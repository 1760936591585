import { ENVIRONMENT } from './constants'
// var callbackUrl = ''
// if (ENVIRONMENT === 'production') {
//   callbackUrl = 'https://signup.alem.health/auth0-callback'
// // } else if (ENVIRONMENT === 'development') {
// //   callbackUrl = 'https://dev-connect-signup-app.azurewebsites.net/auth0-callback'
// } else {
//   callbackUrl = 'http://localhost:3000/auth0-callback'
// }
const getCallbackUrl = () => {
  let currentUrl = window.location.href
  const splittedURL = currentUrl.split('/')
  const protocol = splittedURL[0]
  const siteUrl = splittedURL[2]
  return protocol + '//' + siteUrl + '/auth0-callback'
}
const callbackUrl = getCallbackUrl()

// const AUTH0_ROOT_URL = 'https://alemhealthlog.eu.auth0.com'
// export const AUTH0_TOKEN_URL = AUTH0_ROOT_URL + '/oauth/token'

// const AUTH0_API_URL = AUTH0_ROOT_URL + '/api/v2'
// export const AUTH0_GET_USERS_BY_MAIL = AUTH0_API_URL + '/users-by-email'
// export const AUTH0_CREATE_NEW_USER = AUTH0_API_URL + '/users'
// export const AUTH0_SIGNUP_URL = AUTH0_ROOT_URL + '/dbconnections/signup'


export const AUTH_CONFIG = {
  domain: 'login.alem.health',
  clientID: 'i3ZJ2ibVZePep0aRmiQyCFIoQ7nllwpj', // Telerad Connect Signup
  connection: 'TeleRadUsers',
  audience: 'https://alemhealthapi.azure-api.net/',
  redirectUri: callbackUrl,
  responseType: 'token id_token',
  scope: 'openid email profile'
}
export const FRONTDESK_AUTH_CONFIG = {
  domain: 'login.alem.health',
  clientID: 'GcVAkyTvwNkO4wEPAdCnei9FBOdXzzEx', // Telerad Connect Signup
  connection: 'TeleRadUsers',
  audience: 'https://alemhealthapi.azure-api.net/',
  redirectUri: callbackUrl,
  responseType: 'token id_token',
  scope: 'openid email profile'
}

export const AUTH_MGMT_CONFIG = {
  // apiURL: AUTH0_API_URL,
  domain: 'login.alem.health',
  clientID: 'dCOdJKiTf2Alr3gPVi7uBCSh8Gyz6iQB', // AlemPatient
  audience: 'https://login.alem.health/api/v2/',
  redirectUri: callbackUrl,
  responseType: 'token id_token',
  scope: 'update:current_user_metadata create:current_user_metadata read:current_user, update:users, update:users_app_metadata'
}

// export const AUTH_CONFIG = {
//   domain: 'login.alem.health',
//   clientID: 'qUTlp6sQpnspPY4LLkZhDQzDegLAnYBa', // Alemhealth for Patient
//   redirectUri: callbackUrl,
//   responseType: 'token id_token'
// }
export const META_DATA_PREFIX = 'https://connect.alem.health/'

export const USER_METADATA_KEY = 'https://connect.alem.health/user_meta_data'

export const APP_METADATA_KEY = 'https://connect.alem.health/app_meta_data'
