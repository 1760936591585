import { call, put, takeLatest } from 'redux-saga/effects'
import store from 'store'
import history from '../routers/history'
import {
  GET_CHECK_GROUP_REQUEST,
  GET_CHECK_GROUP_FAILURE,
  POST_CREATE_GROUP_REQUEST,
  POST_CREATE_GROUP_FAILURE,
  getCheckGroupSuccess,
  GET_GROUP_REQUEST,
  GET_GROUP_FAILURE,
  getGroupSuccess,
  UPDATE_GROUP_FAILURE,
  UPDATE_GROUP_REQUEST,
  getGroupRequest,
  postCreateGroupSuccess,
  POST_ASSIGN_GROUP_REQUEST,
  postAssignGroupSuccess,
  POST_ASSIGN_GROUP_FAILURE,
} from '../actions/groupActions'
import { getCheckGroupAPICall, getGroupAPICall, postCreateGroupAPICall, postUpdateGroupAPICall } from '../apis/group'
import { getCurrentIPInfoAPICall } from '../apis/location'
import { postNotificationToSlackAPICall, postNotificationToZapierAPICall, postSignUpInfoToZapierAPICall } from '../apis/slack'
import { SIGN_UP_STEPS, USER_FORM_NAME } from '../config/form-settings'
import { INVITE_ORG, LOCAL_STORAGE_NEW_ORGANIZATION_KEY, LOCAL_STORAGE_NEW_FACILITY_KEY, LOCAL_STORAGE_COUNTRY_LIST_KEY, LOCAL_STORAGE_APPROVED_FACILITIES, LOCAL_STORAGE_GROUP_ID, groupSourceConstant } from '../config/constants'
import { getAzureAddressApiCall } from '../apis/options'
import { patchExistingUserAPICall } from '../apis/user'
// import { uploadFile } from '../utils/azure-blob-helpers'
import {
  clearNotification,
  dataLoadingNotification,
  errorNotification
} from '../utils/notification-helpers'
// import { v4 as uuidV4 } from 'uuid'
import AuthService from '../services/AuthService'
import { postCreateFacilityRequest } from '../actions/facilityActions'
import { postCreateFacilityAPICall } from '../apis/facility'
import { t } from "../i18n";
import { postDemoStudyAPICall } from '../apis/medicialGroup'
import { isEmpty } from 'lodash';
import { startSubmit, stopSubmit } from 'redux-form'


function * getCheckGroupHandler ({ payload }) {
  try {
    const { userGroupName } = payload
    dataLoadingNotification(t("group_saga.loading_organization", {userGroupName: userGroupName}))
    const { responseJSON } = yield call(getCheckGroupAPICall, payload)
    clearNotification()

    if (responseJSON && responseJSON.length > 0) {
      yield put(getCheckGroupSuccess(true))
      yield call(postNotificationToSlackAPICall, { title: '[Create Organization]:', description: 'The organization already exists', jsonContent: payload })
      errorNotification(t("group_saga.radiologist_already_exist"))
    } else {
      // history.replace(`/signup/${SIGN_UP_STEPS['radiology-group-detail'].path}`)
      history.replace(`/signup/${SIGN_UP_STEPS['radiology-group'].path}`)
    }
  } catch (e) {
    console.error('ERROR: ', e)
    yield put({
      type: GET_CHECK_GROUP_FAILURE,
      message: e.message
    })
  }
}

function * getGroupHandler () {
  try {
    const { responseOk, responseJSON } = yield call(getGroupAPICall)
    if (responseOk) {
      yield put(getGroupSuccess(responseJSON))
    }
  } catch (e) {
    console.error('ERROR: ', e)
    yield put({
      type: GET_GROUP_FAILURE,
      message: e.message
    })
  }
}
// we are updating postCreateGroupHandler to include getCheckGroupHandler since figma v1.65 to make create group using single flow
function * postCreateGroupHandler ({ payload }) {
  try {
    if (!payload.isExpedite) yield put(startSubmit(USER_FORM_NAME));
    dataLoadingNotification("processing...")
    const auth = new AuthService()
    const userProfile = auth.getUserProfile()
    const { userGroupName, userIsIndependentRadiologist, redirect = false } = payload || {}
    const response = yield call(getCurrentIPInfoAPICall);
    const country = response?.country ?? null;
    const countryList = store.get(LOCAL_STORAGE_COUNTRY_LIST_KEY)
    let countryName = null;

    if (country && countryList) {
      const singleCountryFound = countryList.find(singleCountry => singleCountry.alpha2Code === country || singleCountry.alpha3Code === country)
      countryName = singleCountryFound.name
    }

    const { responseOk: createGroupResponseOk, responseJSON: createGroupResponse } = yield call(postCreateGroupAPICall, {
      ...payload,
      email_address : userProfile && userProfile.email,
      address: country ? {
        country: countryName || ""
      } : undefined
    })
    if (createGroupResponseOk) {
      const groupId = createGroupResponse && createGroupResponse['group_id'];
      store.set(LOCAL_STORAGE_NEW_ORGANIZATION_KEY, groupId)
      store.set(INVITE_ORG, { groupId, groupName: userGroupName })
      const userId = userProfile && userProfile.sub
      const updatedAppMetadata = {
        app_metadata: {
          affiliated_group: groupId,
          admin: '1',
          main: '1',
          approved_facilities: [],
          associated_groups: []
        }
      }
      const { responseOk: userUpdateOk, responseJSON: userResponseJSON } = yield call(patchExistingUserAPICall, userId, { appMetadata: updatedAppMetadata })
      if(userUpdateOk){
        yield put(getGroupSuccess(userResponseJSON))
        if (userIsIndependentRadiologist && userIsIndependentRadiologist.isIndependentRadiologist) {
          // yield call(postNotificationToSlackAPICall, { title: '[Create Organization]:', description: 'Successfully created organization', jsonContent: createGroupResponse })
          yield call(postNotificationToZapierAPICall, { jsonContent: {...userResponseJSON, ...createGroupResponse } })
          const { userEmail, userPassword, postLoginTarget } = userIsIndependentRadiologist
          auth.login(userEmail, userPassword, postLoginTarget)
          clearNotification()
        } else {
          const accessTokenOk = yield call(auth.getNewTokensForAPI)
          if(typeof accessTokenOk === "string") {
            auth.updateUserAppMetadataInLocalStorage(userResponseJSON.app_metadata)
            const { userGroupLogo } = payload
            let containerURL;
            if(userGroupLogo && userGroupLogo[0]){
              containerURL = yield call (getAzureAddressApiCall, 'grouplogos')
            }
            const requestData = {...payload, groupId, containerURL}
            const { responseOk: updateGroupResponseOk, responseJSON: updateGroupResponse } = yield call(postUpdateGroupAPICall, requestData)
            if(updateGroupResponseOk) {
              // yield call(postNotificationToSlackAPICall, { title: '[Create Organization]:', description: 'Successfully created organization', jsonContent: updateGroupResponse })
              yield call(postNotificationToZapierAPICall, { jsonContent: {...userResponseJSON, ...updateGroupResponse } })
              if(!payload.userFacility) {
                let facilityContainerURL
                if(userGroupLogo && userGroupLogo[0]){
                  facilityContainerURL = yield call (getAzureAddressApiCall, 'facilitylogos')
                }
                const { responseOk: createFacilityStatus, responseJSON: createFacilityResponse } = yield call(postCreateFacilityAPICall, { userFacilityName: requestData.userGroupName, userMemberOf: groupId, userFacilityLogo: userGroupLogo, containerURL: facilityContainerURL, hideProgress: true })
                if(createFacilityStatus){
                  const updatedAppMetadata = {
                    app_metadata: {
                      affiliated_group: groupId,
                      admin: '1',
                      main: '1',
                      approved_facilities: [createFacilityResponse.guid],
                      associated_groups: []
                    }
                  }
                  const { responseOk: patchedUserStatus, responseJSON: patchedUserResponse } = yield call(patchExistingUserAPICall, userId, { appMetadata: updatedAppMetadata })
                  if (patchedUserStatus) yield call(postSignUpInfoToZapierAPICall, { jsonContent: patchedUserResponse, options: { source: groupSourceConstant.creating_new_group } })
                  const  newStudy = yield call(postDemoStudyAPICall, userId, createFacilityResponse?.guid)
                  store.set(LOCAL_STORAGE_NEW_FACILITY_KEY, createFacilityResponse.guid)
                  clearNotification();
                  yield put(postCreateGroupSuccess(createGroupResponse))
                  if (!redirect) {
                    history.replace(`/signup/${SIGN_UP_STEPS['success'].path}`)
                  }
                }
              }
            }
          }
          clearNotification();
          if(payload.userFacility){
            yield put(postCreateFacilityRequest(payload.userFacility))
          }
        }
      }
      if (!payload.isExpedite) yield put(stopSubmit(USER_FORM_NAME));
    } else {
      throw new Error(createGroupResponse)
    }
  } catch (e) {
    clearNotification();
    console.error('ERROR: ', e)
    yield call(postNotificationToSlackAPICall, { title: '[Create Organization]:', description: `Failed to create organization: ${e.message}`, jsonContent: JSON.stringify(payload, null, 2) })
    if (!payload.isExpedite) yield put(stopSubmit(USER_FORM_NAME, {
      userGroupName: 'Failed to create group'
    }));
    yield put({
      type: POST_CREATE_GROUP_FAILURE,
      message: e.message
    })
  }
}

function* postAssignGroupHandler({ payload }) {
  try {
    dataLoadingNotification("processing...")
    const auth = new AuthService()
    const userProfile = auth.getUserProfile()
    const { redirect = false, groupId: payloadGroupId } = payload || {}
    const response = yield call(getCurrentIPInfoAPICall);
    const country = response?.country ?? null;
    const countryList = store.get(LOCAL_STORAGE_COUNTRY_LIST_KEY)
    let countryName = null;

    if (country && countryList) {
      const singleCountryFound = countryList.find(singleCountry => singleCountry.alpha2Code === country || singleCountry.alpha3Code === country)
      countryName = singleCountryFound.name
    }

    const groupId = payloadGroupId || store.get(LOCAL_STORAGE_GROUP_ID)
    if (!isEmpty(groupId)) {
      // store.set(LOCAL_STORAGE_NEW_ORGANIZATION_KEY, groupId)
      // store.set(INVITE_ORG, { groupId, groupName: userGroupName })
      const userId = userProfile && userProfile.sub
      const updatedAppMetadata = {
        app_metadata: {
          affiliated_group: groupId,
          approved_facilities: [],
          associated_groups: []
        }
      }
      const { responseOk: userUpdateOk, responseJSON: userResponseJSON } = yield call(patchExistingUserAPICall, userId, { appMetadata: updatedAppMetadata })
      if (userUpdateOk) {
        yield call(postSignUpInfoToZapierAPICall, { jsonContent: userResponseJSON, options: { source: groupSourceConstant.joining_existing_group } })
        clearNotification();
        yield put(postAssignGroupSuccess())
        if (!redirect) {
          history.replace(`/signup/${SIGN_UP_STEPS['success'].path}`)
        } else {
          history.replace(redirect)
        }
      }
    } else {
      throw new Error('Group ID not found')
    }
  } catch (e) {
    clearNotification();
    console.error('ERROR: ', e)
    yield call(postNotificationToSlackAPICall, { title: '[Create Organization]:', description: `Failed to assign organization: ${e.message}`, jsonContent: payload })
    yield put({
      type: POST_ASSIGN_GROUP_FAILURE,
      message: e.message
    })
  }
}

function * updateGroupSaga ({ payload }) {
  try {
    const { responseOk, responseJSON } = yield call(postUpdateGroupAPICall, payload)

    if(responseOk){
      const { name, group_id } = responseJSON
      store.set(INVITE_ORG, { groupId: group_id, groupName: name })
      history.replace(`/signup/${SIGN_UP_STEPS['invite'].path}`)
    }
  } catch (e) {
    console.error(e)
    yield put({
      type: UPDATE_GROUP_FAILURE,
      message: e.message
    })
  }
}

export function * groupSaga () {
  yield takeLatest(GET_GROUP_REQUEST, getGroupHandler)
  yield takeLatest(GET_CHECK_GROUP_REQUEST, getCheckGroupHandler)
  yield takeLatest(POST_CREATE_GROUP_REQUEST, postCreateGroupHandler)
  yield takeLatest(POST_ASSIGN_GROUP_REQUEST, postAssignGroupHandler)
  yield takeLatest(UPDATE_GROUP_REQUEST, updateGroupSaga)
}
