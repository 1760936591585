import { SLACK_WEB_HOOK, ZAPIER_WEB_HOOK, SLACK_APP_NAME, SLACK_APP_TYPE, allowSlackNotifications, SLACK_WEB_HOOK_TELERADOPS, ZAPIER_INVITE_SIGN_UP_WEB_HOOK, SLACK_WEB_HOOK_DELETE_GROUP, ZAPIER_PARTNER_PROGRAM_WEB_HOOK, ZAPIER_REQUEST_JOIN_AFFILIATE_WEB_HOOK, ZAPIER_SIGNUP_WEB_HOOK, LOCAL_STORAGE_SHARER_ID, LOCAL_STORAGE_SHARE_LINK, LOCAL_STORAGE_STUDY_ID, LOCAL_STORAGE_STUDY_GUID, LOCAL_STORAGE_USER_TYPE } from '../config/constants'
import { getSessionTag } from '../utils/helpers'
import store from "store"

const prepareNotificationPayload = (title, description, jsonContent) => {
  let notificationMessage = `>>>${getSessionTag() ? ` Session: [${getSessionTag()}]` : ""} Title: ${title}`
  notificationMessage += '\n'
  if (description) {
    notificationMessage += '\n'
    notificationMessage += description
  }
  if (jsonContent) {
    notificationMessage += '\n'
    notificationMessage += '```'
    notificationMessage += (jsonContent)
    notificationMessage += '```'
    // forEach(jsonContent, (value, key) => {
    //   notificationMessage += '\n'
    //   if (includes(phoneNumberFields, key)) {
    //     notificationMessage += key + ': ' + value.phone
    //   } else if (key === 'invitees') {
    //     notificationMessage += 'List of Invitees:'
    //     forEach(value, (childValue, childKey) => {
    //       notificationMessage += 'Invitee ' + (childKey + 1) + ': { name: ' + childValue.name + ', email: ' + childValue.email + ' }'
    //     })
    //   } else if (key === 'providerFacilities') {
    //     notificationMessage += key + 'List of Source Facilities:'
    //     forEach(value, (childValue, childKey) => {
    //       notificationMessage += 'Source Facility ' + (childKey + 1) + ': { name: ' + childValue.name + ', country: ' + childValue.country + 'phone: ' + childValue.phone && childValue.phone.phone + ', email: ' + childValue.email + ' }'
    //     })
    //   } else if (key === 'userType') {
    //     notificationMessage += 'Role of the user: ' + value.role
    //     notificationMessage += 'Type of the user: ' + value.contractType
    //   } else {
    //     notificationMessage += key + ': ' + value
    //   }
    // })
  }
  return {
    text: notificationMessage,
    username: SLACK_APP_NAME + '[ ' + SLACK_APP_TYPE + ' ]'
  }
}

export const postNotificationToSlackAPICall = async ({ title, jsonContent = null, description = null, sendToTeleradOps = false }) => {
  try {
    let responseOkByZapier

    if (!allowSlackNotifications) {
      return false
    }

    const payload = prepareNotificationPayload(title, description, jsonContent)
    const config = {
      method: 'POST',
      body: JSON.stringify(payload)
    }
    const slackResponse = await window.fetch(SLACK_WEB_HOOK_TELERADOPS, config)
    const responseOkBySlack = await slackResponse.ok

    return { responseOk: responseOkBySlack, responseOkByZapier }
  } catch (e) {
    console.error('ERROR: ', e)
  }
}

export const postNotificationToSlackForDeleteGroupAPICall = async ({ title, jsonContent = null, description = null }) => {
  try {
    let responseOkByZapier

    if (!allowSlackNotifications) {
      return false
    }

    // const payload = prepareNotificationPayload(title, description, jsonContent)
    const config = {
      method: 'POST',
      body: JSON.stringify({ headertitle: title, headerdescription: description, jsonContent })
    }
    const slackResponse = await window.fetch(SLACK_WEB_HOOK_DELETE_GROUP, config)
    const responseOkBySlack = await slackResponse.ok

    return { responseOk: responseOkBySlack, responseOkByZapier }
  } catch (e) {
    console.error('ERROR: ', e)
  }
}

export const postNotificationToZapierAPICall = async ({ jsonContent = null }) => {
  try {
    let responseOkByZapier

    if (!allowSlackNotifications) {
      return false
    }

    const config = {
      method: 'POST',
      body: JSON.stringify(jsonContent)
    }

    const zapierResponse = await window.fetch(ZAPIER_WEB_HOOK, config)
    responseOkByZapier = await zapierResponse.ok

    return { responseOk: responseOkByZapier }
  } catch (e) {
    console.log('ERROR: ', e)
  }
}

export const postInviteSignUpToZapierAPICall = async ({ jsonContent = null }) => {
  try {
    let responseOkByZapier

    if (!allowSlackNotifications) {
      return false
    }

    const config = {
      method: 'POST',
      body: JSON.stringify(jsonContent)
    }

    const zapierResponse = await window.fetch(ZAPIER_INVITE_SIGN_UP_WEB_HOOK, config)
    responseOkByZapier = await zapierResponse.ok

    return { responseOk: responseOkByZapier }
  } catch (e) {
    console.log('ERROR: ', e)
  }
}



export const postPartnerProgramNotificationToZapierAPICall = async ({ jsonContent = null }) => {
  try {
    let responseOkByZapier
    const config = {
      method: 'POST',
      body: JSON.stringify(jsonContent)
    }
    const zapierResponse = await window.fetch(ZAPIER_PARTNER_PROGRAM_WEB_HOOK, config)
    responseOkByZapier = await zapierResponse.ok
    return { responseOk: responseOkByZapier }
  } catch (e) {
    console.log('ERROR: ', e)
  }
}

export const postRequestAccessJoinAffiliateZapierAPICall = async (payload) => {
  try {
    let responseOkByZapier
    const config = {
      method: 'POST',
      body: JSON.stringify({...payload})
    }
    const zapierResponse = await window.fetch(ZAPIER_REQUEST_JOIN_AFFILIATE_WEB_HOOK, config)
    responseOkByZapier = await zapierResponse.ok
    return { responseOk: responseOkByZapier }
  } catch (e) {
    console.log('ERROR: ', e)
  }
}

export const postSignUpInfoToZapierAPICall = async ({ jsonContent = null, options = {} }) => {
  try {
    let responseOkByZapier

    if (!jsonContent) throw new Error('jsonContent is required')

    const userId = store.get(LOCAL_STORAGE_SHARER_ID)
    if (userId) jsonContent.sharer_id = userId
    const shareLink = store.get(LOCAL_STORAGE_SHARE_LINK)
    if (shareLink) jsonContent.share_link = shareLink
    const studyShareId = store.get(LOCAL_STORAGE_STUDY_ID)
    if (studyShareId) jsonContent.study_share_id = studyShareId
    const studyGuid = store.get(LOCAL_STORAGE_STUDY_GUID)
    if (studyGuid) jsonContent.study_guid = studyGuid
    const userType = store.get(LOCAL_STORAGE_USER_TYPE)
    if (userType) jsonContent.user_type = userType

    if (options && options.source) {
      jsonContent.source = options.source
    }

    jsonContent.version = process.env.REACT_APP_VERSION

    const config = {
      method: 'POST',
      body: JSON.stringify(jsonContent)
    }

    const zapierResponse = await window.fetch(ZAPIER_SIGNUP_WEB_HOOK, config)
    responseOkByZapier = zapierResponse.ok

    return { responseOk: responseOkByZapier }
  } catch (e) {
    console.log('ERROR: ', e)
  }
}