import store from 'store'
import { FRONTDESK_STORAGE_USER, LOCAL_STORAGE_SESSION_TAG_KEY, LOCAL_STORAGE_SOURCE_PATH, shareLinkPathList } from '../config/constants'
import { USER_TYPE } from '../config/form-settings'
export const prepareDataForSignUp = (sourceData) => {
  if (!sourceData) {
    return {}
  }
  const { userEmail, title, userPhone, userPassword, looking, userFullName, contractType } = sourceData
  const { phone, countryName, dialCode } = userPhone || {};

  const allowFrontDesk =  store.get(FRONTDESK_STORAGE_USER) ? "1" : "0";

  return {
    email: userEmail,
    password: userPassword,
    name: userFullName,
    user_metadata: {
      allowed_to_access_front_desk: allowFrontDesk,
      name: userFullName,
      phone_number: phone,
      prefix: dialCode,
      user_type: looking ? looking : contractType ? contractType : USER_TYPE[USER_TYPE.length - 1].value,
      title: title,
      home_facility: '',
      specialty: '',
      signature: '',
      country: countryName
    }
  }
}

export const setSessionTag = (email) => {
  const currentDate = new Date()
  // const currentYear = currentDate.getFullYear()
  // const currentMonth = currentDate.getMonth() + 1
  // const currentMonthString = currentMonth < 10 ? '0' + currentMonth : currentMonth
  // const currentDayOfMonth = currentDate.getDate()
  // const currentDateString = currentDayOfMonth < 10 ? '0' + currentDayOfMonth : currentDayOfMonth
  const currentTimestamp = currentDate.getTime()
  // const re = / /gi
  // const dateString = currentYear + '-' + currentMonthString + '-' + currentDateString // currentDate.toDateString().toLowerCase().replace(re, '-')
  // const sessionTag = 'session_' + dateString + '-' + currentTimestamp
  const sessionTag = email + '_on_' + currentTimestamp
  store.set(LOCAL_STORAGE_SESSION_TAG_KEY, sessionTag)
}

export const getSessionTag = () => {
  return store.get(LOCAL_STORAGE_SESSION_TAG_KEY)
}

export const checkShareLink = () => {
  const sourcePath = store.get(LOCAL_STORAGE_SOURCE_PATH) || null;
  return sourcePath ? shareLinkPathList.includes(sourcePath) : false
}

const waitForNeverBounce = (timeout = 10000, interval = 100) => {
  return new Promise((resolve, reject) => {
    const startTime = Date.now();

    const checkNeverBounce = () => {
      if (typeof window._nb !== 'undefined' && window._nb.api) {
        resolve(window._nb);
      } else if (Date.now() - startTime > timeout) {
        reject(new Error('NeverBounce not loaded within timeout period'));
      } else {
        setTimeout(checkNeverBounce, interval);
      }
    };

    checkNeverBounce();
  });
};

export const verifyEmail = async (email) => {
  try {
    const _nb = await waitForNeverBounce();

    if (!_nb) {
      console.error('NeverBounce is not available');
      return {
        valid: false,
        result: null,
      };
    }

    const result = await new Promise((resolve, reject) => {
      _nb.api.getValidatePublic(
        email,
        (result) => resolve(result),
        (err) => reject(err)
      );
    });

    return {
      valid: result.is([0,3,4]),
      result,
    };
  } catch (error) {
    console.error(error);
    return {
      valid: false,
      result: null,
    };
  }
}