import React, { Component, lazy } from 'react'
import { Redirect, Route, Switch, withRouter } from 'react-router-dom'
import LoadingSpinner from '../components/partials/LoadingSpinner'
import ReactGA from 'react-ga4'
import AuthService from '../services/AuthService'

const DesktopHomeComponent = lazy(() => import('../components/Home'))
const DesktopSignUpContainer = lazy(() => import('../containers/DesktopSignUpContainer'))
const QrScannedComponent = lazy(() => import('../components/QrScannedComponent'))
const QrPatientStudy = lazy(() => import('../components/QrPatientStudy'))
const QrMedicalProfessional = lazy(() => import('../components/QrMedicalProfessional'))
// const DesktopUserContainer = lazy(() => import('../containers/DesktopUserContainer'))
// const DesktopFacilityContainer = lazy(() => import('../containers/DesktopFacilityContainer'))
// const DesktopInviteContainer = lazy(() => import('../containers/DesktopInviteContainer'))

const auth = new AuthService()

const handleAuthentication = (nextState, replace) => {
  if (/access_token|id_token|error/.test(nextState.location.hash)) {
    auth.handleAuthentication()
  }
}

const PublicRoute = ({ component: Component, ...rest }) => {
  // if (isProduction) {
  //   analytics.load(segmentIOWriteKey)
  //   analytics.page(rest.path, { path: rest.path })
  // }
  return (
    <Route {...rest} render={(props) => (<Component {...props} />)} />
  )
}

class RoutesContainer extends Component {
  constructor(props) {
    super(props);

    if (props?.history?.location?.pathname) {
      ReactGA.send({hitType: "pageview", page: props.history.location.pathname});
    }

    ReactGA.event({
      category: "Custom testing event",
      action: "testing action",
    });
  }
  componentDidUpdate(props) {
    if (props?.history?.location?.pathname) {
      ReactGA.send({hitType: "pageview", page: props.history.location.pathname});
    }
  }

  
  render () {
    return (
      <Switch>
        <Route exact path='/auth0-callback' render={(props) => {
          handleAuthentication(props)
          return <LoadingSpinner {...props} />
        }} />
        <PublicRoute exact path='/' component={DesktopHomeComponent} />
        <Route exact path='/signup/' render={(props) => {
          const { location } = props

          return <Redirect to={`/signup/user-type${location?.search}`} />
        }} />
        <PublicRoute exact path='/signup/:currentPage' component={DesktopSignUpContainer} />
        <PublicRoute exact path='/qr-scanned' component={QrScannedComponent} />
        <PublicRoute exact path='/qr-scanned-patient' component={QrPatientStudy} />
        <PublicRoute exact path='/qr-scanned-medical-professional' component={QrMedicalProfessional} />
        <PublicRoute exact path='/share-link' component={QrMedicalProfessional} />
        {/* <PublicRoute exact path='/register/user' component={DesktopUserContainer} />
        <PublicRoute exact path='/register/user/:stepNumber' component={DesktopUserContainer} />
        <PublicRoute exact path='/register/organization/:stepNumber' component={DesktopFacilityContainer} />
        <PublicRoute exact path='/register/invite/:stepNumber' component={DesktopInviteContainer} /> */}
      </Switch>
    )
  }
}

export default withRouter(RoutesContainer)
